body {
  overflow-x: hidden;
  background-color: #000007;
  font-size: $medium;
  min-height: 100vh;
}

p + p {
  margin: 0;
}

button {
  width: auto;
  // background: $primary;
  padding: $s;
  border-radius: 50px;
  transition: 0.7s;
  padding-left: $xxl;
  padding-right: $xxl;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 1px;
  color: white;
  font-family: $txtFamily;

  &.full {
    width: 100%;
  }

  &.primary {
    background: white;
    border: 1px solid white;
    color: black;
    &:hover {
      background: $white;
      color: $black;
      border: 1px solid $lightgray;
    }
  }

  &.secondary {
    background: $white;
    border: 1px solid $primary;
    color: $secondary;
  }

  &.dark {
    background: $black;
    border: 1px solid $black;
    color: $white;
  }

  &:hover {
    transition: 0.7s;
    -webkit-box-shadow: 0px 0px 6px 2px $lightgray;
    -moz-box-shadow: 0px 0px 6px 2px $lightgray;
    box-shadow: 0px 0px 6px 2px $lightgray;
  }

  &.small {
    font-size: 10px;
    padding: 10px;
    min-width: 75px;
  }

  &.tag {
    font-size: $small;
    padding-top: calc($xs / 1.5);
    padding-bottom: calc($xs / 1.5);
    &:hover {
      background: $white;
      color: $txt;
      border: 1px solid $lightgray;
    }
  }

  &.text {
    background: none;
    border: 1px solid none;
    color: $altTxt;
  }

  &.light-text {
    background: none;
    border: 1px solid none;
    color: $altTxt;
  }

  &.dark-text {
    background: none;
    border: 1px solid none;
    color: $black;
  }

  &.borderless {
    border: 0px;
  }
}

dd {
  margin-bottom: 0px;
}

span.primary,
p.primary {
  color: $txt;
  font-weight: 600;
  font-family: $txtFamily;
}

#WEB3_CONNECT_MODAL_ID > div {
  z-index: 50;
}

.opacity {
  width: 100%;
  height: 100%;
  display: block;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;

  &.white {
    background-color: rgba(255, 255, 255, 0.4);
  }
}

.divider {
  width: 100%;
  display: block;
  height: 15px;
  background-color: $white;
}

.desktop {
  display: block;
}

.mobile {
  display: none;
}

.shadow {
  -webkit-box-shadow: 0px 0px 6px 0px $lightgray;
  -moz-box-shadow: 0px 0px 6px 0px $lightgray;
  box-shadow: 0px 0px 6px 0px $lightgray;
}

.loading-overlay {
  display: block;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0);
  z-index: 99;
}

.above-video {
  z-index: 2;
}

.gradient {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    40deg,
    rgba(0, 0, 0, 1) 0%,
    $primary 0%,
    $secondary 100%
  );
}

.gradient-txt {
  background: -webkit-linear-gradient(45deg, $primary, $secondary);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.ant-drawer-header-title {
  flex-direction: row-reverse;
}

.ant-drawer-content-wrapper {
  min-width: 378px !important;
  width: 30vw !important;
}

.ant-drawer-close {
  padding: 0;
  min-width: 50px;
  display: flex;
  justify-content: flex-end;
  &:hover {
    box-shadow: none;
    border-radius: 0;
  }
}

.ant-btn-sm {
  font-size: 12px;
}

.ant-modal-close,
.img-crop-control-zoom button {
  min-width: 50px;
}

.social-btn {
  min-width: auto;
}

pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

#WEB3_CONNECT_MODAL_ID > div {
  z-index: 9999;
}

.item-content p.counter {
  font-weight: 300 !important;
}

.rainbow {
  animation: colorRotate 2s linear 0s infinite;
}

.inner-page {
  background: linear-gradient(-45deg, #21202b, #000007);
  padding-bottom: 30px;
  min-height: 100vh;
}

@keyframes colorRotate {
  from {
    color: $primary;
  }
  50% {
    color: $secondary;
  }
  100% {
    color: $primary;
  }
}

@media only screen and (max-width: 840px) {
  .wrapper {
    width: auto;
    max-width: initial;
  }

  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }
}
